import MuiCircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import React from 'react';
import styled from 'styled-components';

interface ICircularProgress extends CircularProgressProps {}

const CustomCircularProgress = styled(MuiCircularProgress)`
  &.MuiCircularProgress-colorPrimary {
    color: ${props => props.theme.paletteDefault.primary.main};
  }
`;

const CircularProgress: React.FC<ICircularProgress> = ({
  children,
  ...props
}) => {
  return <CustomCircularProgress {...props} />;
};

export default CircularProgress;
