import Image from 'next/image';
import styled from 'styled-components';
import { IRatio } from '@/interfaces/index';
import { IImageWithRatio } from './IImageWithRatio';

const Container = styled.div<IRatio>`
  position: relative;
  padding-top: ${props => (props.ratio.height / props.ratio.width) * 100}%;
  line-height: 0;
  width: 100%;
  /* max-width: ${props => props.ratio.width}px; */
`;

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

const ImageWithRatio: React.FC<IImageWithRatio> = props => {
  const { media, ratio, alt } = props;

  return (
    <Container ratio={ratio}>
      <ImageContainer>
        {media &&
        media.fields &&
        media.fields.file &&
        media.fields.file.details &&
        media.fields.file.details.image ? (
          <Image
            src={`https:${media?.fields?.file?.url}`}
            alt={alt ? alt : ''}
            width={media?.fields?.file?.details.image.width}
            height={media?.fields?.file?.details.image.height}
            unoptimized={true}
          />
        ) : null}
      </ImageContainer>
    </Container>
  );
};

export default ImageWithRatio;
