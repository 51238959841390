import React from 'react';
import { useTheme } from 'styled-components';

const IconDoubleArrow: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  const theme = useTheme();
  const color1 = theme.icon.color1;
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        d="m1.57,3.91c-.1-.1,0-.34.19-.34h4.09c.1,0,.19.05.29.15l6.92,8.04c.15.19.15.24,0,.44l-6.92,8.08c-.1.1-.19.15-.29.15H1.76c-.19,0-.29-.24-.19-.34l6.92-7.89c.1-.15.14-.24,0-.44L1.57,3.91Zm9.3,0c-.1-.1,0-.34.19-.34h4.09c.1,0,.19.05.29.15l6.92,8.04c.15.19.15.24,0,.44l-6.92,8.08c-.1.1-.19.15-.29.15h-4.09c-.19,0-.29-.24-.19-.34l6.92-7.89c.1-.15.15-.24,0-.44l-6.92-7.84Z"
        fill={props.color || color1}
      />
    </svg>
  );
};

export default IconDoubleArrow;
