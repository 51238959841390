import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import {
  Typography,
  Button,
  ImageWithRatio,
  LinkHandler,
} from '@/components/atoms';
import { mapImgWithTextColors } from '@/utils/index';

import { IImageWithTextCmp } from '../IImageWithTextCmp';

const Container = styled.div`
  position: relative;
  line-height: 0;
  & img {
    border-radius: 24px;
  }
`;

const InfoContainerMobile = styled.div<IInfoContainer>`
  ${props =>
    props.template === 'banner_hero_text_center' &&
    css<IInfoContainer>`
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 16px;
      position: static;
      border-radius: 24px;
      margin-bottom: 16px;
      background-color: ${props => props.bgColor};
      ${props => props.theme.breakpoints.up('md')} {
        display: none;
      }
    `}
` as React.ElementType;

const InfoContainerDesktop = styled.div<IInfoContainer>`
  ${props =>
    props.template === 'banner_hero_text_center' &&
    css`
      display: none;
      ${props => props.theme.breakpoints.up('md')} {
        width: 33.333333%;
        display: flex;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 16px;
        background-color: transparent;
      }
    `}
` as React.ElementType;

const HeadingContainer = styled.div``;
const CustomTypo = styled(Typography)``;

const DescContainer = styled.div<IDescContainer>`
  width: 100%;
  max-width: ${props =>
    props.maxWidthText ? `${props.maxWidthText}px` : '100%'};
  margin: auto;
  margin-top: ${props =>
    props.mbott ? 'clamp(1.5rem, 1.5rem + 0vw, 1.5rem)' : '0'};
`;

const LinkContainer = styled.div`
  margin-top: clamp(1.5rem, 0.9902rem + 2.0915vw, 3.5rem); //24px - 56px
`;

const ImageDesktop = styled.div`
  display: none;
  ${props => props.theme.breakpoints.up('sm')} {
    display: block;
  }
`;

const ImageMobile = styled.div`
  display: block;
  ${props => props.theme.breakpoints.up('sm')} {
    display: none;
  }
`;

interface IInfoContainer {
  template?: string;
  noimage?: boolean;
  bgColor?: string;
}

interface IDescContainer {
  maxWidthText?: number;
  mbott?: boolean;
}

const ImageWithTextHero: React.FC<IImageWithTextCmp> = props => {
  const {
    headingText,
    url,
    label,
    mediaMobile,
    media,
    maxWidthText,
    template,
    desc,
    textColor,
    altTag,
    bgColor,
    ctaColor,
    borderColor,
  } = props;

  let noimage = false;
  if (media) {
    noimage = true;
  }
  const showInfoContainer =
    headingText === '' && desc === null && url === '' ? false : true;
  const theme = useTheme();
  const { ctaColorMapped, textColorMapped } = mapImgWithTextColors(
    { borderColor, textColor, ctaColor },
    theme
  );

  const infoContent = (
    <>
      <HeadingContainer>
        {headingText ? (
          <CustomTypo
            datatype="heading_3"
            textcolor={textColorMapped}
            component={'h2'}
            dangerouslySetInnerHTML={{
              __html: headingText,
            }}
            className="bigtitle"
          />
        ) : null}
        {desc ? (
          <DescContainer
            maxWidthText={maxWidthText}
            mbott={url !== '' ? true : false}>
            <Typography datatype="subtitle_2" textcolor={textColorMapped}>
              {desc}
            </Typography>
          </DescContainer>
        ) : null}
      </HeadingContainer>
      {url && (
        <LinkContainer>
          <LinkHandler href={url} passHref source={`cmsLink-iwtb-${template}`}>
            <a>
              <Button variant={'text'} color={ctaColorMapped}>
                {label}
              </Button>
            </a>
          </LinkHandler>
        </LinkContainer>
      )}
    </>
  );

  const infoContainerMobile = (
    <InfoContainerMobile
      template={template}
      noimage={noimage}
      bgColor={bgColor}>
      {infoContent}
    </InfoContainerMobile>
  );

  const infoContainerDesktop = (
    <InfoContainerDesktop
      template={template}
      noimage={noimage}
      bgColor={bgColor}>
      {infoContent}
    </InfoContainerDesktop>
  );

  return (
    <Container>
      {showInfoContainer && infoContainerMobile}
      {media &&
        media.fields &&
        media.fields.file &&
        media.fields.file.details &&
        media.fields.file.details.image && (
          <ImageDesktop>
            <ImageWithRatio
              ratio={{
                width: media?.fields?.file?.details?.image?.width,
                height: media?.fields?.file?.details?.image?.height,
              }}
              media={media}
              alt={altTag}
            />
          </ImageDesktop>
        )}
      {mediaMobile &&
        mediaMobile.fields &&
        mediaMobile.fields.file &&
        mediaMobile.fields.file.details &&
        mediaMobile.fields.file.details.image && (
          <ImageMobile>
            <ImageWithRatio
              ratio={{
                width: mediaMobile?.fields?.file?.details.image.width,
                height: mediaMobile?.fields?.file?.details.image.height,
              }}
              media={mediaMobile}
              alt={altTag}
            />
          </ImageMobile>
        )}

      {showInfoContainer && infoContainerDesktop}
    </Container>
  );
};

export default ImageWithTextHero;
