import React from 'react';
import styled, { css } from 'styled-components';
import { CircularProgress, IconDoubleArrow } from '@/components/atoms';

interface IButton {
  children: React.ReactNode;
  variant?: 'contained' | 'outlined' | 'text' | 'link';
  loading?: boolean;
  size?: 'small' | 'medium' | 'large';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  backGroundColor?: string;
  color?: string;
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
  fullWidth?: boolean;
  href?: string;
  startIcon?: React.ReactNode;
  textTransform?: 'uppercase' | 'lowercase' | 'capitalize';
}

const CustomButton = styled.div<IButton>`
  font-family: ${props => props?.theme?.button?.fontFamily || 'Arial'};
  position: relative;
  cursor: pointer;
  border: none;
  letter-spacing: 1px;
  padding: 10px 28px;
  font-size: clamp(1.125rem, 1.0931rem + 0.1307vw, 1.25rem); //18px - 20px
  line-height: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${props => props?.theme?.button?.borderRadius}px;

  ${props =>
    props?.variant === 'contained' &&
    css<IButton>`
      background-color: ${props =>
        props?.backGroundColor ||
        props?.theme?.button?.contained?.backgroundColor};
      color: ${props =>
        props?.color || props?.theme?.button?.contained?.color} !important;
      border: 1px solid transparent;
    `}
  ${props =>
    props?.variant === 'outlined' &&
    css<IButton>`
      background-color: ${props => props?.theme?.button?.contained?.color};
      border: 1px solid
        ${props => props?.color || props?.theme?.button?.outlined?.borderColor};
      color: ${props =>
        props?.color || props?.theme?.button?.outlined?.color} !important;
    `}

    ${props =>
    props?.variant === 'text' &&
    css<IButton>`
      background-color: transparent;
      color: ${props =>
        props?.color || props?.theme?.button?.outlined?.color} !important;
      text-transform: ${props => props.textTransform || 'uppercase'};
      padding: 4px 0px 4px 0px;
      ${props =>
        props.size === 'small' &&
        css`
          padding: 4px 0px 4px 0px !important;
        `}
      ${props =>
        props.size === 'medium' &&
        css`
          padding: 4px 0px 4px 0px !important;
        `}
    `}

    ${props =>
    props?.variant === 'link' &&
    css<IButton>`
      background-color: transparent;
      color: ${props =>
        props?.color || props?.theme?.button?.outlined?.color} !important;
      text-transform: ${props => props.textTransform || 'uppercase'};
      border-radius: 0px;
      border-bottom: 2px solid
        ${props => props?.color || props?.theme?.button?.outlined?.color};
      padding: 4px 0px 2px 0px;
      ${props =>
        props.size === 'small' &&
        css`
          padding: 0px 0px 2px 0px !important;
        `}
      ${props =>
        props.size === 'medium' &&
        css`
          padding: 0px 0px 2px 0px !important;
        `}
    `}

    ${props =>
    props.size === 'small' &&
    css`
      padding: 6px 16px;
      font-size: clamp(0.75rem, 0.7181rem + 0.1307vw, 0.875rem); //12px - 14px
    `}

    ${props =>
    props.size === 'medium' &&
    css`
      padding: 8px 24px;
      font-size: clamp(0.875rem, 0.8431rem + 0.1307vw, 1rem); //14px - 16px
    `}

    ${props =>
    props.fullWidth &&
    css`
      display: block;
      width: 100%;
    `} 

 

  ${props =>
    props.disabled &&
    css`
      cursor: default;
      opacity: 0.4;
      & a {
        cursor: default;
      }
    `}
`;

const IconContainer = styled.span`
  color: inherit;
  margin-right: 8px;
`;

const IconContainerEnd = styled.span<IBtnEnd>`
  color: inherit;
  margin-left: 4px;
  height: 18px;
  ${props =>
    props.size === 'medium' &&
    css`
      height: 16px;
    `}
  ${props =>
    props.size === 'small' &&
    css`
      height: 14px;
    `}
`;

interface IBtnEnd {
  size?: 'small' | 'medium' | 'large';
}

const Button: React.FC<IButton> = ({
  children,
  variant,
  size,
  loading,
  disabled,
  onClick,
  href,
  startIcon,
  textTransform,
  ...rest
}) => {
  const component = href ? 'a' : 'button';

  let doubleArrowSize = 16;

  if (size === 'small') {
    doubleArrowSize = 12;
  }
  if (size === 'medium') {
    doubleArrowSize = 14;
  }

  return (
    <CustomButton
      variant={variant}
      size={size}
      disabled={disabled}
      onClick={onClick}
      as={component}
      href={href}
      textTransform={textTransform}
      {...rest}>
      {loading && (
        <IconContainer className="loading">
          <CircularProgress
            size={size === 'small' ? 10 : 14}
            color={'inherit'}
          />
        </IconContainer>
      )}
      {startIcon && <IconContainer>{startIcon}</IconContainer>}
      {children}
      {variant === 'text' && (
        <IconContainerEnd size={size}>
          {' '}
          <IconDoubleArrow
            width={doubleArrowSize}
            height={doubleArrowSize}
            color={rest.color}
          />
        </IconContainerEnd>
      )}
    </CustomButton>
  );
};

Button.defaultProps = {
  variant: 'contained',
};

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    variant?: 'contained' | 'outlined' | 'text' | 'link';
  }
}

export default Button;
